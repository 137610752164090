import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
// import jwt_decode from 'jwt-decode'
import axios from "axios";
import shareVideo from "../assets/share.mp4";
import logo from "../assets/logowhite.png";

import {client} from '../client'

const Login = ({showDashboard}) => {
  const navigate = useNavigate();

  const loginHandler = (response) => {
    localStorage.setItem('user', JSON.stringify(response));
    console.log(localStorage.setItem('user', JSON.stringify(response)));

    const userName = response.name;
    const image = response.picture;
    const _id = response.sub;

    // console.log(userName, _id, image)
    const doc = {
      _id, _type: "user", userName, image
    }

    client.createIfNotExists(doc)
    .then(()=>{
      navigate('/',{replace: true})
    })
    // console.log("I'm Called!")
    showDashboard();
  }

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        loginHandler(res.data);
      } catch (e) {
        console.log(e.message);
      }
    },
    onError: (error) => console.log(error),
  });

  return (
    <div className="flex justify-start items-center flex-col h-screen">
      <div className="relative w-full h-full">
        <video
          src={shareVideo}
          type="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          className="w-full h-full object-cover"
        />
        <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
          <div className="p-5">
            <img src={logo} alt="logo" width="130px" />
          </div>
          <div className="shadow-2xl">
            <button
              type="button"
              className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
              onClick={() => login()}
            >
              <FcGoogle className="mr-4" /> Sign in with Google
            </button>

            {/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
                console.log(jwt_decode(credentialResponse.credential))
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
