import React, { useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Home from "./container/Home";

const App = () => {
  const [user, setUser] = useState(null);
  const showDashboard = () => {
    const User = JSON.parse(localStorage.getItem("user"));
    setUser(User);
    // console.log(User);
  };
  useState(()=>{
    showDashboard();
  },[])
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}>
      <Routes>
        <Route path="login" element={<Login showDashboard={showDashboard} />} />
        {user && <Route path="/*" element={<Home />} />}
        {!user && <Route path="/*" element={<Navigate to='login' replace/>} />}
      </Routes>
    </GoogleOAuthProvider>
  );
};

export default App;
